// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "@angular/material/theming";

// SCSS Variables
@import "var";

// Mixins
@import "partials/mixins";

// Fonts
@import "partials/fonts/inter";

// MEDIA 
@import "./include-media";

// override form field styles

@import "./form-controls";

// rtl support css
@import "./rtl";

// Styles / CSS Variables
@import "partials/styles/style-dark";
@import "partials/styles/style-light";
@import "partials/styles/style-default";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($config);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hodhod-theme);

// Partials
@import "partials/horizontal";
@import "partials/misc";
@import "partials/vertical";
@import "partials/print";
@import "partials/typography";
@import "partials/plugins/@angular/material/index";
@import "partials/plugins/apexcharts/apexcharts";

// Layouts
@import "partials/layouts/layout-apollo";
@import "partials/layouts/layout-ares";
@import "partials/layouts/layout-hermes";
@import "partials/layouts/layout-ikaros";
@import "partials/layouts/layout-poseidon";
@import "partials/layouts/layout-zeus";

/* native scrolbbar */
::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background-color: #e0e0e0;
  }
  ::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.3rem;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    box-shadow: inset 0 0 0.3rem rgba(0, 0, 0, 0.3);
    background-color: #dadfe6;
  }
  * {
    scrollbar-color: light;
    scrollbar-width: thin;
    scrollbar-color: #dadfe6 #f5f5f5;
  }

// Plus imports for other components in your app.

/* You can add global styles to this file, and also import other style files */

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  overflow-x: hidden;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11", "salt", "calt", "case";
  @apply font-sans leading-normal antialiased;
}

body {
  height: 100%;
  letter-spacing: -0.006em;
  @apply text-sm text-black;
}

div {
  box-sizing: border-box;
}


::selection {
  color: #ffffff;
  background: rgba(var(--color-primary), 1);
}

.snack-bar-warring {
  background-color: orange !important;
  color: var(--color-on-error) !important;
}

.snack-bar-error {
  background-color: var(--color-error) !important;
  color: var(--color-on-error) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  background-color: transparent !important;
}

