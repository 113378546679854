@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// hodhod Core
@import "./@hodhod/styles/core";
@import "../node_modules/angular-calendar/scss/angular-calendar";
@import "./@hodhod/styles/partials/plugins/angular-calendar/angular-calendar";
@import "@hodhod/styles/new-table";
@import "./@hodhod/styles/partials/plugins/@angular/material/checkbox";
@import "./@hodhod/styles/partials/plugins/@angular/material/radio-button";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @hodhod/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  --color-primary: #1f36b4;
  --color-primary-contrast: 255, 255, 255;

  --color-accent: 34, 34, 34;
  --color-accent-contrast: 255, 255, 255;

  // --color-warn: yellow;
  // --color-warn-contrast: black;

  --color-background: #f3f3f3;
  --color-primary-dark: #222222;
  --color-primary-light: rgba(238, 67, 64, 0.1);
  --color-primary-overlay: #f6faf3;
  --color-on-primary-high-emphasis: #ffffff;
  --color-on-primary-medium-emphasis: #d7e3f1;
  --color-surface-dark: #e7e8ec;
  --color-surface-medium: #f7f7f7;
  --color-surface-light: #ffffff;
  --color-on-surface-disabled: rgba(255, 255, 255, 0.5);
  --color-on-surface-high-emphasis: #393e46;
  --color-on-surface-medium-emphasis: #6b778d;
  --color-on-surface-light-emphasis: rgba(152, 152, 152, 1);
  --color-border-dark: #c8c9cc;
  --color-border-medium: #d7e3f1;
  --color-border-light: rgba(107, 119, 141, 0.3);
  --color-error: #ff3c00;
  --color-on-error: #ffffff;
  --color-on-print: #3e42a1;

  // Reported Issues Severity
  --color-severity-low: #b4e55c;
  --color-severity-medium: #4cd964;
  --color-severity-heigh: #ff3c00;

  // Reported Issues Status
  --color-status-new: #ff3c00;
  --color-status-in-progress: #f59200;
  --color-status-closed: #137b25;

  // Reported Issues Type
  --color-issue-type-un-safe-condition: #d4a506;
  --color-issue-type-incident-reporting​: rgb(var(--color-primary));
}

.mat-row {
  height: auto;
  box-sizing: border-box;
}

.mat-cell {
  padding: 8px !important;
  box-sizing: border-box;
}

td.mat-cell:first-of-type {
  padding-left: 16px !important;
}

.snack-bar-warring {
  background-color: orange !important;
  color: var(--color-on-error) !important;
}

.snack-bar-error {
  background-color: var(--color-error) !important;
  color: var(--color-on-error) !important;
}

.mat-table .mat-header-cell {
  font-weight: bold;
}

app-basic-button {
  margin-left: 15px !important;
}

.field {
  width: calc(50% - 15px);

  @include media("<=tablet") {
    width: 100%;
  }
}

.field-medium {
  width: calc(32% - 15px);

  @include media("<=tablet") {
    width: 100%;
  }
}

.field-small {
  width: calc(25% - 15px);

  @include media("<=tablet") {
    width: 100%;
  }
}

.field-full {
  width: 100%;

  @include media("<=tablet") {
    width: 100%;
  }
}

.my-custom-tooltip {
  word-break: break-all;
}

mat-tab-group
  > mat-tab-header
  > div.mat-tab-label-container
  > div
  > div
  > div.mat-ripple.mat-tab-label {
  box-sizing: border-box;
  height: 40px;
  width: 88px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
  color: #989898;
  background-color: rgba(0, 0, 0, 0.02) !important;

  @media (max-width: 599px) {
    min-width: 160px !important;
  }

  &.mat-tab-label-active {
    border-bottom: 1px solid #ffffff;
    box-shadow: 0 0 4px #aa9c9c;
    color: rgba(var(--color-primary), 1);
    background-color: #ffffff !important;
  }
}

.panel_wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.panel_wrapper:last-child {
  margin-bottom: 0;
}

::ng-deep .mat-form-field-outline {
  background-color: var(--color-surface-light);
  border-radius: 4px;
}

.select-options {
  visibility: hidden;
  width: 100%;
  height: 0;
  margin-top: 45px;
  position: absolute;
}

.avatar-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  .avatar {
    height: auto;
    width: auto;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid #67dd2d;
    background-color: #eff6ff;
  }

  .label {
    color: #ffffff;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
    border-radius: 12px;
    background-color: #67dd2d;
    position: absolute;
    z-index: 20;
    padding: 3px 15px 3px 15px;
    margin-bottom: -3px;
  }
}

.verify-button {
  margin-top: 30px;
  color: rgba(var(--color-primary), 1);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-decoration: underline;
}

.image_wrapper {
  width: 100%;
  height: 550px;
  position: relative;
  border-radius: 5px;
  overflow: scroll;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cardSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  padding: 3em 6em;
}

.avatar-content {
  width: 100% !important;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
}

.full-width {
  // min-width: 100% !important;
  max-width: calc(100vw - 128px) !important;
}

.feature_card_bg {
  background-color: #ffffff;
}

.panal-overflow-auto
  mat-accordion
  mat-expansion-panel
  > .mat-expansion-panel-content {
  overflow: auto !important;
}

.table-input-field {
  width: 150px !important;
  padding: 0 !important;
  .mat-form-field-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 0.5em 0 !important;
      }
    }
  }
  .mat-form-field-subscript-wrapper {
    margin-top: 2em !important;
  }
}
.small-input-field {
  width: 100%;
  padding: 0 !important;
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 0.6em 0 !important;
      }
    }
  }
}

.mat-form-field-subscript-wrapper{
  position: relative !important;
}

.mat-select-arrow-wrapper{
  height: 0 !important;
}

.edit-list-item .mat-slide-toggle-thumb {
  top: 6px !important;
  right: -6px !important;
  position: relative;
}

.edit-list-item .mat-slide-toggle-bar {
  background-color: #b6b4ba;
  border-radius: 36px !important;
  height: 27px !important;
  width: 46px !important;
}
.edit-list-item
  .mat-slide-toggle:not(.mat-checked)
  .mat-slide-toggle-bar::after {
  content: "Show";
  font-size: 60%;
  font-weight: 700;
  color: var(--color-primary);
  position: absolute;
  left: 20px;
  top: 21px;
}
.edit-list-item .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(var(--color-primary));
}
.edit-list-item .mat-slide-toggle.mat-checked .mat-slide-toggle-bar::after {
  content: "Hide";
  font-size: 60%;
  font-weight: 700;
  color: rgb(var(--color-primary));
  position: absolute;
  left: 4px;
  top: 21px;
}
.edit-list-item .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff;
}

.tab-btn {
  border: none !important;
  box-shadow: none !important;
}

:host ::ng-deep .mat-tab-label-active {
  .mat-tab-label-content {
    color: #2e2c34 !important;
  }

  border-color: rgb(var(--color-primary)) !important;
}

.dashboard-tabs.mat-tab-group
  > .mat-tab-header
  > div.mat-tab-label-container
  > div
  > div
  > div.mat-ripple.mat-tab-label {
  box-shadow: none;
  border: none;
  color: #2e2c34;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: transparent !important;

  &.mat-tab-label-active {
    box-shadow: none;
    border: none;
    color: #2e2c34 !important;
    background-color: #ffffff !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.step-checkbox {
  &.mat-checkbox-checked .mat-checkbox-background {
    width: 17px;
    height: 17px;
    background-color: #00af41 !important;
  }

  .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame,
  .mat-checkbox-background {
    width: 17px;
    height: 17px;
    border-radius: 100% !important;
  }
}

.qrcode-dialog .mat-dialog-container {
  border-radius: 35px !important;
  padding: 20px 45px !important;
}

.rounded-mat-dialog .mat-dialog-container {
  border-radius: 30px !important;
}

.dashboard-full-screen {
  min-width: 70vw !important;
  min-height: 80vh !important;
  .mat-bottom-sheet-container {
    min-width: 100% !important;
    border-radius: 25px 25px 0px 0px;
  }
}

// .mat-select-color-High{
//   .mat-select-trigger{
//     .mat-select-value {
//       .mat-select-value-text{
//         color: rgb(var(--color-primary)) !important;
//       }
//    }
//   }
// }
// .mat-select-color-Medium{
//   .mat-select-trigger{
//     .mat-select-value {
//       .mat-select-value-text{
//         color: #E49114 !important;

//       }
//    }
//   }
// }.mat-select-color-Low{
//   .mat-select-trigger{
//     .mat-select-value {
//       .mat-select-value-text{
//         color: #FFEA30 !important;

//       }
//    }
//   }
// }
.mat-div:hover {
  background-color: #bcbec12e !important;
  .mat-icon {
    color: #000000 !important;
  }
}
.mat-div .mat-icon {
  margin: 0 !important;
}

.line-broken-tooltip {
  white-space: pre-line;
}

.audit-collabse {
  .mat-expansion-panel-body {
    padding: 10px 0px 20px 10px !important;
  }
}

.is-invalid {
  color: red;
}

.is-valid {
  color: rgb(52, 23, 214);
}
::ng-deep .mat-dialog-container {
  direction: ltr;
}

.custom-slide-toggle .mat-slide-toggle-thumb {
  top: 6px !important;
  right: -6px !important;
  position: relative;
}

.custom-slide-toggle .mat-slide-toggle-bar {
  background-color: #b6b4ba;
  border-radius: 36px !important;
  height: 27px !important;
  width: 46px !important;
}
.custom-toggle .custom-slide-toggle .mat-slide-toggle-thumb {
  top: 5px !important;
  right: -5px !important;
  left: 2px !important;
  position: relative;
}

.custom-toggle .custom-slide-toggle .mat-slide-toggle-bar {
  background-color: #b6b4ba;
  border-radius: 36px !important;
  height: 20px !important;
  width: 36px !important;
}

.custom-slide-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(var(--color-primary));
}
.custom-slide-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff;
}
.custom-toggle .custom-slide-toggle .mat-slide-toggle-thumb {
  height: 16px;
    width: 16px;
}

.custom-invite-user-input {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.chatbot-toggle {
  .mat-button-wrapper {
    padding: 0px !important;
    background-color: transparent;
  }
}

.chatbot-dialog {
  .mat-dialog-container {
    padding: 0;
    border-radius: 15px !important;
    overflow: hidden;
  }
  .mat-dialog-content {
    margin: 0;
    padding: 8px !important;
    width: 100%;
    height: 100%;
  }
  .mat-dialog-actions {
    margin-bottom: 0px !important;
    padding: 20px 30px;
  }
}

.new-upload-file {
  .upload-input {
    width: 100% !important;
    color: #475467 !important;
  }
  .upload-input:hover {
    color: #475467 !important;
  }
}
