.hodhod-layout-poseidon {
  $sidenav-background: #0a1929;
  --sidenav-item-min-height: 40px;

  --sidenav-item-background-hover: rgba(var(--color-primary), 0.1);
  --sidenav-item-background-active: var(--background-foreground);
  --sidenav-item-dropdown-background: var(--sidenav-background);
  --sidenav-item-dropdown-background-hover: rgba(var(--color-primary), 0.1);
  --sidenav-item-dropdown-background-active: rgba(var(--color-primary), 0.2);

  --sidenav-item-padding-vertical: var(--padding-8);
  --sidenav-item-padding-horizontal: var(--padding-12);

  .sidenav {
    border: none;

    hodhod-sidenav-item {
      .hodhod-sidenav-item {
        width: unset;
        @apply rounded py-2 mx-4;

        &.hodhod-sidenav-item--active {
          @apply shadow;
        }
      }

      .item-level-0>.hodhod-sidenav-item {
        @apply my-1;
      }

      .item-level-1 .hodhod-sidenav-item.hodhod-sidenav-item--active {
        @apply shadow-none font-medium;

        .hodhod-sidenav-item__label {
          @apply text-black;
        }
      }

      .item-level-1 .hodhod-sidenav-item:hover .hodhod-sidenav-item__label {
        @apply text-black;
      }
    }
  }

  // .hodhod-sidenav-item {
  //   // @apply mx-3 mb-1 rounded;
  //   width: unset;
  //   @apply rounded-full py-2 mx-4;

  //   &.hodhod-sidenav-item--active {
  //     @apply shadow;
  //   }
  // }

  .hodhod-sidenav-toolbar__divider {
    margin: 0 var(--sidenav-item-padding-horizontal);
  }

  &.hodhod-style-light {
    --sidenav-item-color: rgba(255, 255, 255, 1);
    --sidenav-item-color-hover: rgba(255, 255, 255, 1);
    --sidenav-item-dropdown-color-hover: rgba(0, 0, 0, 1);
    --sidenav-item-dropdown-color-active: rgba(0, 0, 0, 1);
    --sidenav-item-dropdown-background-active: rgba(255, 255, 255, 0.8);
  }

  &.hodhod-style-default {
    --sidenav-background: #{$sidenav-background};
    --sidenav-toolbar-background: var(--sidenav-background);
    --sidenav-item-color: rgba(255, 255, 255, 0.8);
    --sidenav-item-color-hover: rgba(255, 255, 255, 1);
    --sidenav-item-ripple-color: rgb(255, 255, 255, 0.06);
  }

  &.hodhod-style-dark {
    --sidenav-toolbar-background: theme('backgroundColor.foreground');
    --sidenav-background: theme('backgroundColor.foreground');
    --sidenav-item-color: rgba(255, 255, 255, 0.8);
    --sidenav-item-color-hover: #FFFFFF;
    --sidenav-item-ripple-color: rgb(255, 255, 255, 0.06);
    --sidenav-item-dropdown-background-active: rgb(14 14 14 / 64%);
  }
}