@use '@angular/material' as mat;
@import "../../var";

.hodhod-style-dark {
  @include mat.all-component-themes($hodhod-dark-theme);

  // Foreground
  --background-app-bar: #{map-get(map-get($hodhod-dark-theme, background), app-bar)};

  // Background
  --footer-background: var(--background-foreground);
  --navigation-background: #{map-get(map-get($hodhod-dark-theme, background), card)};
  --toolbar-background: #{map-get(map-get($hodhod-dark-theme, background), card)};
  --background-base: #{map-get(map-get($hodhod-dark-theme, background), background)};

  // Colors
  --background-foreground: #{map-get(map-get($hodhod-dark-theme, background), card)};
  --background-foreground-rgb: #{red(map-get(map-get($hodhod-dark-theme, background), card))},
  #{green(map-get(map-get($hodhod-dark-theme, background), card))},
  #{blue(map-get(map-get($hodhod-dark-theme, background), card))};
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: #{$light-primary-text};
  --toolbar-color: #{$light-primary-text};
  --text-color-light: #{$dark-primary-text};

  // Toolbar
  --foreground-divider: #{map-get(map-get($hodhod-dark-theme, foreground), divider)};
  --text-hint: #{$light-disabled-text};

  // Navigation
  --text-hint-light: #{$dark-disabled-text};
  // --background-hover: #{map-get(map-get($hodhod-dark-theme, background), hover)};
  --background-hover: rgb(26, 32, 46); 
  // Secondary Toolbar
  --text-secondary: #{$light-secondary-text};

  // Footer
  --text-secondary-light: #{$dark-secondary-text};
  --secondary-toolbar-background: var(--background-foreground);

  .mat-form-field-outline {
    background-color: var(--color-on-surface-high-emphasis);
  }

  .mat-expansion-panel {
    background-color: var(--navigation-background) !important;
  }

  .mat-expansion-panel-header-title {
    color: #ffffff !important;
  }

  .mat-checkbox-label {
    color: #ffffff !important;
  }

  .field-label {
    color: #ffffff !important;
  }

  input {
    color: #ffffff !important;
  }

  input::placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .db-card-wrapper {
    background-color: #1F36B4 !important;

    .content-card-section1 {
      background-color: #1F36B4 !important;

      .content-card-left-side {
        .card-value {
          color: #fff !important;
        }
      }
    }

    .content-card-section2 {
      background-color: #1F36B4 !important;

      .content-card-left-side {
        .card-value {
          color: #fff !important;
        }
      }
    }

    .content-card-section3 {
      background-color: #1F36B4 !important;

      .content-card-left-side {
        .card-value {
          color: #fff !important;
        }
      }
    }
  }

  .mat-option-text {
    color: #ffffff !important;
  }

  .mat-slide-toggle-content {
    color: #ffffff !important;
  }

  .car_wrapper {
    background-color: var(--navigation-background) !important;
  }

  // .item-level-1.hodhod-sidenav-item--active {
  //   background-color: #00000025;
  // }

  .highcharts-background {
    fill: #1F36B4 !important;
  }

  .highcharts-button-box {
    fill: #1F36B4 !important;
  }

  .chart-body {
    background-color: #1F36B4 !important;
  }

  .highcharts-drilldown-axis-label {
    fill: #fff !important;
  }

  .highcharts-legend-item {
    text {
      fill: #fff !important;
    }
  }

  .highcharts-breadcrumbs-button text {
    fill: #fff !important;
  }

  .card_wrapper {
    .card {
      background-color: #1F36B4 !important;
    }
  }

  .list-body {
    background-color: #1F36B4 !important;
  }

  .violation-db-pannel {
    .chart-header {
      background-color: #1F36B4;
    }
  }

  .mat-pseudo-checkbox {
    color: #ffffff;
    background-color: rgba(var(--color-primary), 1) !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #ffffff;
  }

  // .mat-radio-label-content {
  //   color: #ffffff !important;
  // }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #ffffff !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: rgba(var(--color-primary), 1) !important;
  }



  .badge-custom {
    color: #ffffff !important;
  }

  .mat-select-disabled .mat-select-value {
    color: #ffffff !important;
  }

  .mat-select-disabled .mat-select-value .mat-select-value-text span {
    color: #ffffff !important;
  }

  .ng-tns-c309-11 {
    background: #1F36B4 !important;
  }

  [_nghost-vuq-c309] {
    background: #1F36B4 !important;
  }

  file-upload {
    background: #1F36B4 !important;
  }

  .info_dark {
    background: #0c0f15 !important;
    color: #ffffff !important;
  }
  .notification_card {
    background-color: var(--color-primary-light) !important;
  }
  .notification_card_description {
    color: #ffffff !important;
  }

  .card, .search_card {
    background: #1F36B4 !important;
  }

  .feature_card_bg {
    background-color: #1F36B4 !important;
  }
  
  .popup { 
    background: #262324 !important;
  }

  .ngx-gauge-meter {
    color: #ffffff !important;
  }

  .ngx-mat-select-search-box-wrap {
    background: #262324 !important;
  }
  .ngx-mat-select-not-found-container {
    background: #1F36B4 !important;
  }

  textarea {
    color: #ffffff !important;
  }

  .hodhod-sidenav-item__icon {
    svg {
      filter: brightness(0) invert(1) !important;
    }
  }
  .content_icon_filter {
    svg {
      filter: brightness(0) invert(1) !important;
    }
  }
  
}