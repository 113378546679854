.new-table {
  th.mat-header-cell {
    background-color: #f9fafb !important;
    color: #344054 !important;
    border-top: 1px solid #d0d5dd !important;
    border-bottom: 1px solid #d0d5dd !important;
  }
  tr.mat-header-row,
  tr.mat-cell {
    height: 48px;
  }
  th.mat-header-cell:first-of-type{
    padding-left: 16px;
  }
}
