.new-radio {
    color: #344054;
    font-size: 14;
  .mat-radio-container {
    display: none !important;
  }
  .mat-radio-label {
    border: 1 solid gray !important;
  }
  .mat-radio-label-content {
    padding: 4px 8px 4px 8px;
    border: 1px solid #D0D5DD !important;
    border-radius: 4px;
  }
  .mat-radio-checked {
    background-color: #efefef;
    border-radius: 4px;

  }
}
