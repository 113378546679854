body[dir="rtl"]
  > app-root
  > app-login-wrapper
  > div
  > div
  > div.content-panel.flex.align-center.reverse
  > div.background-vertical.flex.center {
  left: 4%;
  right: unset;

  @include media("<desktop") {
    right: 0;
    left: 0;
  }
}

body[dir="rtl"] {
  .flex.gaps:not(.column):not(.reverse) > :not(:last-child) {
    margin-left: var(--flex-gap, 1em) !important;
    margin-right: unset !important;
  }
}

// language button
body[dir="rtl"]
  > app-root
  > app-wrapper
  > app-main-page
  > app-drawer
  > mat-drawer-container
  > mat-drawer-content
  > div
  > div.top-header
  > app-top-header
  > mat-toolbar
  > div
  > div.language-dropdown {
  right: unset !important;
  left: 0px;
}
body[dir="rtl"] app-root > app-not-found-page > div > app-top-header > mat-toolbar > div > div.language-dropdown {
  right: unset !important;
  left: 0px;
}

// module selector
body[dir="rtl"]
  > app-root
  > app-wrapper
  > app-main-page
  > app-modal
  > div
  > div
  > div.modal-body
  > div
  > app-add-company
  > div
  > form
  > div.modules.flex
  > div
  > app-company-module
  > div.selector {
  right: 185px;
}

body[dir="rtl"]
  > app-root
  > app-wrapper
  > app-main-page
  > app-drawer
  > mat-drawer-container
  > mat-drawer-content
  > div
  > div.page
  > app-view-reported-issue-page
  > div
  > app-state-section
  > div
  > div
  > div.container-controls
  > div.comment-history
  > div.comment-item {
  &::before {
    left: unset !important;
    right: 0;
  }
}
