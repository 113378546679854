@import "partials/fonts/inter";

.field-label {
    height: 16px;
    width: 100%;
    // color: #171d29;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}

.input-field {
    width: 100%;
    margin-bottom: 15px;

    * > .mat-form-field-disabled {
        & > .mat-form-field-wrapper {
            .mat-form-field-flex {
                // background-color: #ededee !important;
                // color: #222222;
            }

            * > .mat-form-field-outline-start {
                border: none;
            }

            * > .mat-form-field-outline-end {
                border: none;
            }
        }
    }

    &.select-options {
        margin-bottom: 30px;
    }

    input {
        background-color: transparent !important;
    }

    textarea {
        min-height: 120px;
    }
}

.error-img {
    height: 12px;
    margin-inline-end: 15px;
}

.selector {
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    // background-color: var(--color-surface-light);
    left: 185px;
    top: -15px;
    z-index: 1;

    &.selected {
        border: 7.5px solid rgba(var(--color-primary), 1);
    }
}

mat-label {
    // color: var(--color-on-surface-high-emphasis);
    @extend .font-H5;
    margin-bottom: 4px;
}

::ng-deep .mat-select-disabled .mat-select-value .mat-select-value-text span {
    color: rgba(black, 0.38) !important;
}

::ng-deep .mat-form-field-appearance-outline {
    .mat-form-field-prefix,
    .mat-form-field-suffix {
        top: 0.40em !important;
    }
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
    border-top: 0.24375em solid transparent;
}
